import { __extends } from "tslib";
import { ServiceException as __ServiceException, } from "@aws-sdk/smithy-client";
var CognitoIdentityProviderServiceException = (function (_super) {
    __extends(CognitoIdentityProviderServiceException, _super);
    function CognitoIdentityProviderServiceException(options) {
        var _this = _super.call(this, options) || this;
        Object.setPrototypeOf(_this, CognitoIdentityProviderServiceException.prototype);
        return _this;
    }
    return CognitoIdentityProviderServiceException;
}(__ServiceException));
export { CognitoIdentityProviderServiceException };
