import { __assign, __extends } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "./CognitoIdentityProviderServiceException";
import { AttributeTypeFilterSensitiveLog, UserPoolClientTypeFilterSensitiveLog, } from "./models_0";
var EnableSoftwareTokenMFAException = (function (_super) {
    __extends(EnableSoftwareTokenMFAException, _super);
    function EnableSoftwareTokenMFAException(opts) {
        var _this = _super.call(this, __assign({ name: "EnableSoftwareTokenMFAException", $fault: "client" }, opts)) || this;
        _this.name = "EnableSoftwareTokenMFAException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, EnableSoftwareTokenMFAException.prototype);
        return _this;
    }
    return EnableSoftwareTokenMFAException;
}(__BaseException));
export { EnableSoftwareTokenMFAException };
export var VerifySoftwareTokenResponseType;
(function (VerifySoftwareTokenResponseType) {
    VerifySoftwareTokenResponseType["ERROR"] = "ERROR";
    VerifySoftwareTokenResponseType["SUCCESS"] = "SUCCESS";
})(VerifySoftwareTokenResponseType || (VerifySoftwareTokenResponseType = {}));
export var SignUpResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StartUserImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StartUserImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StopUserImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StopUserImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TagResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TagResourceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UntagResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UntagResourceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateAuthEventFeedbackRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.FeedbackToken && { FeedbackToken: SENSITIVE_STRING }))); };
export var UpdateAuthEventFeedbackResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateDeviceStatusRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var UpdateDeviceStatusResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateGroupRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateGroupResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateIdentityProviderRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateIdentityProviderResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateResourceServerRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateResourceServerResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateUserAttributesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
})), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var UpdateUserAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateUserPoolRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateUserPoolResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateUserPoolClientRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var UpdateUserPoolClientResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClient && { UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient) }))); };
export var UpdateUserPoolDomainRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UpdateUserPoolDomainResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerifySoftwareTokenRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var VerifySoftwareTokenResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerifyUserAttributeRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var VerifyUserAttributeResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
