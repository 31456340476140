import { __assign, __extends } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "./CognitoIdentityProviderServiceException";
export var RecoveryOptionNameType;
(function (RecoveryOptionNameType) {
    RecoveryOptionNameType["ADMIN_ONLY"] = "admin_only";
    RecoveryOptionNameType["VERIFIED_EMAIL"] = "verified_email";
    RecoveryOptionNameType["VERIFIED_PHONE_NUMBER"] = "verified_phone_number";
})(RecoveryOptionNameType || (RecoveryOptionNameType = {}));
export var AccountTakeoverEventActionType;
(function (AccountTakeoverEventActionType) {
    AccountTakeoverEventActionType["BLOCK"] = "BLOCK";
    AccountTakeoverEventActionType["MFA_IF_CONFIGURED"] = "MFA_IF_CONFIGURED";
    AccountTakeoverEventActionType["MFA_REQUIRED"] = "MFA_REQUIRED";
    AccountTakeoverEventActionType["NO_ACTION"] = "NO_ACTION";
})(AccountTakeoverEventActionType || (AccountTakeoverEventActionType = {}));
export var AttributeDataType;
(function (AttributeDataType) {
    AttributeDataType["BOOLEAN"] = "Boolean";
    AttributeDataType["DATETIME"] = "DateTime";
    AttributeDataType["NUMBER"] = "Number";
    AttributeDataType["STRING"] = "String";
})(AttributeDataType || (AttributeDataType = {}));
var InternalErrorException = (function (_super) {
    __extends(InternalErrorException, _super);
    function InternalErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "InternalErrorException", $fault: "server" }, opts)) || this;
        _this.name = "InternalErrorException";
        _this.$fault = "server";
        Object.setPrototypeOf(_this, InternalErrorException.prototype);
        return _this;
    }
    return InternalErrorException;
}(__BaseException));
export { InternalErrorException };
var InvalidParameterException = (function (_super) {
    __extends(InvalidParameterException, _super);
    function InvalidParameterException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidParameterException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidParameterException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidParameterException.prototype);
        return _this;
    }
    return InvalidParameterException;
}(__BaseException));
export { InvalidParameterException };
var NotAuthorizedException = (function (_super) {
    __extends(NotAuthorizedException, _super);
    function NotAuthorizedException(opts) {
        var _this = _super.call(this, __assign({ name: "NotAuthorizedException", $fault: "client" }, opts)) || this;
        _this.name = "NotAuthorizedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NotAuthorizedException.prototype);
        return _this;
    }
    return NotAuthorizedException;
}(__BaseException));
export { NotAuthorizedException };
var ResourceNotFoundException = (function (_super) {
    __extends(ResourceNotFoundException, _super);
    function ResourceNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceNotFoundException.prototype);
        return _this;
    }
    return ResourceNotFoundException;
}(__BaseException));
export { ResourceNotFoundException };
var TooManyRequestsException = (function (_super) {
    __extends(TooManyRequestsException, _super);
    function TooManyRequestsException(opts) {
        var _this = _super.call(this, __assign({ name: "TooManyRequestsException", $fault: "client" }, opts)) || this;
        _this.name = "TooManyRequestsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TooManyRequestsException.prototype);
        return _this;
    }
    return TooManyRequestsException;
}(__BaseException));
export { TooManyRequestsException };
var UserImportInProgressException = (function (_super) {
    __extends(UserImportInProgressException, _super);
    function UserImportInProgressException(opts) {
        var _this = _super.call(this, __assign({ name: "UserImportInProgressException", $fault: "client" }, opts)) || this;
        _this.name = "UserImportInProgressException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserImportInProgressException.prototype);
        return _this;
    }
    return UserImportInProgressException;
}(__BaseException));
export { UserImportInProgressException };
var UserNotFoundException = (function (_super) {
    __extends(UserNotFoundException, _super);
    function UserNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "UserNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "UserNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserNotFoundException.prototype);
        return _this;
    }
    return UserNotFoundException;
}(__BaseException));
export { UserNotFoundException };
var InvalidLambdaResponseException = (function (_super) {
    __extends(InvalidLambdaResponseException, _super);
    function InvalidLambdaResponseException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidLambdaResponseException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidLambdaResponseException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidLambdaResponseException.prototype);
        return _this;
    }
    return InvalidLambdaResponseException;
}(__BaseException));
export { InvalidLambdaResponseException };
var LimitExceededException = (function (_super) {
    __extends(LimitExceededException, _super);
    function LimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "LimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "LimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, LimitExceededException.prototype);
        return _this;
    }
    return LimitExceededException;
}(__BaseException));
export { LimitExceededException };
var TooManyFailedAttemptsException = (function (_super) {
    __extends(TooManyFailedAttemptsException, _super);
    function TooManyFailedAttemptsException(opts) {
        var _this = _super.call(this, __assign({ name: "TooManyFailedAttemptsException", $fault: "client" }, opts)) || this;
        _this.name = "TooManyFailedAttemptsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TooManyFailedAttemptsException.prototype);
        return _this;
    }
    return TooManyFailedAttemptsException;
}(__BaseException));
export { TooManyFailedAttemptsException };
var UnexpectedLambdaException = (function (_super) {
    __extends(UnexpectedLambdaException, _super);
    function UnexpectedLambdaException(opts) {
        var _this = _super.call(this, __assign({ name: "UnexpectedLambdaException", $fault: "client" }, opts)) || this;
        _this.name = "UnexpectedLambdaException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnexpectedLambdaException.prototype);
        return _this;
    }
    return UnexpectedLambdaException;
}(__BaseException));
export { UnexpectedLambdaException };
var UserLambdaValidationException = (function (_super) {
    __extends(UserLambdaValidationException, _super);
    function UserLambdaValidationException(opts) {
        var _this = _super.call(this, __assign({ name: "UserLambdaValidationException", $fault: "client" }, opts)) || this;
        _this.name = "UserLambdaValidationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserLambdaValidationException.prototype);
        return _this;
    }
    return UserLambdaValidationException;
}(__BaseException));
export { UserLambdaValidationException };
export var DeliveryMediumType;
(function (DeliveryMediumType) {
    DeliveryMediumType["EMAIL"] = "EMAIL";
    DeliveryMediumType["SMS"] = "SMS";
})(DeliveryMediumType || (DeliveryMediumType = {}));
export var MessageActionType;
(function (MessageActionType) {
    MessageActionType["RESEND"] = "RESEND";
    MessageActionType["SUPPRESS"] = "SUPPRESS";
})(MessageActionType || (MessageActionType = {}));
export var UserStatusType;
(function (UserStatusType) {
    UserStatusType["ARCHIVED"] = "ARCHIVED";
    UserStatusType["COMPROMISED"] = "COMPROMISED";
    UserStatusType["CONFIRMED"] = "CONFIRMED";
    UserStatusType["FORCE_CHANGE_PASSWORD"] = "FORCE_CHANGE_PASSWORD";
    UserStatusType["RESET_REQUIRED"] = "RESET_REQUIRED";
    UserStatusType["UNCONFIRMED"] = "UNCONFIRMED";
    UserStatusType["UNKNOWN"] = "UNKNOWN";
})(UserStatusType || (UserStatusType = {}));
var CodeDeliveryFailureException = (function (_super) {
    __extends(CodeDeliveryFailureException, _super);
    function CodeDeliveryFailureException(opts) {
        var _this = _super.call(this, __assign({ name: "CodeDeliveryFailureException", $fault: "client" }, opts)) || this;
        _this.name = "CodeDeliveryFailureException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, CodeDeliveryFailureException.prototype);
        return _this;
    }
    return CodeDeliveryFailureException;
}(__BaseException));
export { CodeDeliveryFailureException };
var InvalidPasswordException = (function (_super) {
    __extends(InvalidPasswordException, _super);
    function InvalidPasswordException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidPasswordException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidPasswordException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidPasswordException.prototype);
        return _this;
    }
    return InvalidPasswordException;
}(__BaseException));
export { InvalidPasswordException };
var InvalidSmsRoleAccessPolicyException = (function (_super) {
    __extends(InvalidSmsRoleAccessPolicyException, _super);
    function InvalidSmsRoleAccessPolicyException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidSmsRoleAccessPolicyException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidSmsRoleAccessPolicyException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidSmsRoleAccessPolicyException.prototype);
        return _this;
    }
    return InvalidSmsRoleAccessPolicyException;
}(__BaseException));
export { InvalidSmsRoleAccessPolicyException };
var InvalidSmsRoleTrustRelationshipException = (function (_super) {
    __extends(InvalidSmsRoleTrustRelationshipException, _super);
    function InvalidSmsRoleTrustRelationshipException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidSmsRoleTrustRelationshipException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidSmsRoleTrustRelationshipException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidSmsRoleTrustRelationshipException.prototype);
        return _this;
    }
    return InvalidSmsRoleTrustRelationshipException;
}(__BaseException));
export { InvalidSmsRoleTrustRelationshipException };
var PreconditionNotMetException = (function (_super) {
    __extends(PreconditionNotMetException, _super);
    function PreconditionNotMetException(opts) {
        var _this = _super.call(this, __assign({ name: "PreconditionNotMetException", $fault: "client" }, opts)) || this;
        _this.name = "PreconditionNotMetException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, PreconditionNotMetException.prototype);
        return _this;
    }
    return PreconditionNotMetException;
}(__BaseException));
export { PreconditionNotMetException };
var UnsupportedUserStateException = (function (_super) {
    __extends(UnsupportedUserStateException, _super);
    function UnsupportedUserStateException(opts) {
        var _this = _super.call(this, __assign({ name: "UnsupportedUserStateException", $fault: "client" }, opts)) || this;
        _this.name = "UnsupportedUserStateException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnsupportedUserStateException.prototype);
        return _this;
    }
    return UnsupportedUserStateException;
}(__BaseException));
export { UnsupportedUserStateException };
var UsernameExistsException = (function (_super) {
    __extends(UsernameExistsException, _super);
    function UsernameExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "UsernameExistsException", $fault: "client" }, opts)) || this;
        _this.name = "UsernameExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UsernameExistsException.prototype);
        return _this;
    }
    return UsernameExistsException;
}(__BaseException));
export { UsernameExistsException };
var AliasExistsException = (function (_super) {
    __extends(AliasExistsException, _super);
    function AliasExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "AliasExistsException", $fault: "client" }, opts)) || this;
        _this.name = "AliasExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, AliasExistsException.prototype);
        return _this;
    }
    return AliasExistsException;
}(__BaseException));
export { AliasExistsException };
var InvalidUserPoolConfigurationException = (function (_super) {
    __extends(InvalidUserPoolConfigurationException, _super);
    function InvalidUserPoolConfigurationException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidUserPoolConfigurationException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidUserPoolConfigurationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidUserPoolConfigurationException.prototype);
        return _this;
    }
    return InvalidUserPoolConfigurationException;
}(__BaseException));
export { InvalidUserPoolConfigurationException };
export var AuthFlowType;
(function (AuthFlowType) {
    AuthFlowType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    AuthFlowType["ADMIN_USER_PASSWORD_AUTH"] = "ADMIN_USER_PASSWORD_AUTH";
    AuthFlowType["CUSTOM_AUTH"] = "CUSTOM_AUTH";
    AuthFlowType["REFRESH_TOKEN"] = "REFRESH_TOKEN";
    AuthFlowType["REFRESH_TOKEN_AUTH"] = "REFRESH_TOKEN_AUTH";
    AuthFlowType["USER_PASSWORD_AUTH"] = "USER_PASSWORD_AUTH";
    AuthFlowType["USER_SRP_AUTH"] = "USER_SRP_AUTH";
})(AuthFlowType || (AuthFlowType = {}));
export var ChallengeNameType;
(function (ChallengeNameType) {
    ChallengeNameType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    ChallengeNameType["CUSTOM_CHALLENGE"] = "CUSTOM_CHALLENGE";
    ChallengeNameType["DEVICE_PASSWORD_VERIFIER"] = "DEVICE_PASSWORD_VERIFIER";
    ChallengeNameType["DEVICE_SRP_AUTH"] = "DEVICE_SRP_AUTH";
    ChallengeNameType["MFA_SETUP"] = "MFA_SETUP";
    ChallengeNameType["NEW_PASSWORD_REQUIRED"] = "NEW_PASSWORD_REQUIRED";
    ChallengeNameType["PASSWORD_VERIFIER"] = "PASSWORD_VERIFIER";
    ChallengeNameType["SELECT_MFA_TYPE"] = "SELECT_MFA_TYPE";
    ChallengeNameType["SMS_MFA"] = "SMS_MFA";
    ChallengeNameType["SOFTWARE_TOKEN_MFA"] = "SOFTWARE_TOKEN_MFA";
})(ChallengeNameType || (ChallengeNameType = {}));
var MFAMethodNotFoundException = (function (_super) {
    __extends(MFAMethodNotFoundException, _super);
    function MFAMethodNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "MFAMethodNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "MFAMethodNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, MFAMethodNotFoundException.prototype);
        return _this;
    }
    return MFAMethodNotFoundException;
}(__BaseException));
export { MFAMethodNotFoundException };
var PasswordResetRequiredException = (function (_super) {
    __extends(PasswordResetRequiredException, _super);
    function PasswordResetRequiredException(opts) {
        var _this = _super.call(this, __assign({ name: "PasswordResetRequiredException", $fault: "client" }, opts)) || this;
        _this.name = "PasswordResetRequiredException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, PasswordResetRequiredException.prototype);
        return _this;
    }
    return PasswordResetRequiredException;
}(__BaseException));
export { PasswordResetRequiredException };
var UserNotConfirmedException = (function (_super) {
    __extends(UserNotConfirmedException, _super);
    function UserNotConfirmedException(opts) {
        var _this = _super.call(this, __assign({ name: "UserNotConfirmedException", $fault: "client" }, opts)) || this;
        _this.name = "UserNotConfirmedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserNotConfirmedException.prototype);
        return _this;
    }
    return UserNotConfirmedException;
}(__BaseException));
export { UserNotConfirmedException };
export var ChallengeName;
(function (ChallengeName) {
    ChallengeName["Mfa"] = "Mfa";
    ChallengeName["Password"] = "Password";
})(ChallengeName || (ChallengeName = {}));
export var ChallengeResponse;
(function (ChallengeResponse) {
    ChallengeResponse["Failure"] = "Failure";
    ChallengeResponse["Success"] = "Success";
})(ChallengeResponse || (ChallengeResponse = {}));
export var FeedbackValueType;
(function (FeedbackValueType) {
    FeedbackValueType["INVALID"] = "Invalid";
    FeedbackValueType["VALID"] = "Valid";
})(FeedbackValueType || (FeedbackValueType = {}));
export var EventResponseType;
(function (EventResponseType) {
    EventResponseType["Failure"] = "Failure";
    EventResponseType["Success"] = "Success";
})(EventResponseType || (EventResponseType = {}));
export var RiskDecisionType;
(function (RiskDecisionType) {
    RiskDecisionType["AccountTakeover"] = "AccountTakeover";
    RiskDecisionType["Block"] = "Block";
    RiskDecisionType["NoRisk"] = "NoRisk";
})(RiskDecisionType || (RiskDecisionType = {}));
export var RiskLevelType;
(function (RiskLevelType) {
    RiskLevelType["High"] = "High";
    RiskLevelType["Low"] = "Low";
    RiskLevelType["Medium"] = "Medium";
})(RiskLevelType || (RiskLevelType = {}));
export var EventType;
(function (EventType) {
    EventType["ForgotPassword"] = "ForgotPassword";
    EventType["SignIn"] = "SignIn";
    EventType["SignUp"] = "SignUp";
})(EventType || (EventType = {}));
var UserPoolAddOnNotEnabledException = (function (_super) {
    __extends(UserPoolAddOnNotEnabledException, _super);
    function UserPoolAddOnNotEnabledException(opts) {
        var _this = _super.call(this, __assign({ name: "UserPoolAddOnNotEnabledException", $fault: "client" }, opts)) || this;
        _this.name = "UserPoolAddOnNotEnabledException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserPoolAddOnNotEnabledException.prototype);
        return _this;
    }
    return UserPoolAddOnNotEnabledException;
}(__BaseException));
export { UserPoolAddOnNotEnabledException };
var InvalidEmailRoleAccessPolicyException = (function (_super) {
    __extends(InvalidEmailRoleAccessPolicyException, _super);
    function InvalidEmailRoleAccessPolicyException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidEmailRoleAccessPolicyException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidEmailRoleAccessPolicyException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidEmailRoleAccessPolicyException.prototype);
        return _this;
    }
    return InvalidEmailRoleAccessPolicyException;
}(__BaseException));
export { InvalidEmailRoleAccessPolicyException };
var CodeMismatchException = (function (_super) {
    __extends(CodeMismatchException, _super);
    function CodeMismatchException(opts) {
        var _this = _super.call(this, __assign({ name: "CodeMismatchException", $fault: "client" }, opts)) || this;
        _this.name = "CodeMismatchException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, CodeMismatchException.prototype);
        return _this;
    }
    return CodeMismatchException;
}(__BaseException));
export { CodeMismatchException };
var ExpiredCodeException = (function (_super) {
    __extends(ExpiredCodeException, _super);
    function ExpiredCodeException(opts) {
        var _this = _super.call(this, __assign({ name: "ExpiredCodeException", $fault: "client" }, opts)) || this;
        _this.name = "ExpiredCodeException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ExpiredCodeException.prototype);
        return _this;
    }
    return ExpiredCodeException;
}(__BaseException));
export { ExpiredCodeException };
var SoftwareTokenMFANotFoundException = (function (_super) {
    __extends(SoftwareTokenMFANotFoundException, _super);
    function SoftwareTokenMFANotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "SoftwareTokenMFANotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "SoftwareTokenMFANotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, SoftwareTokenMFANotFoundException.prototype);
        return _this;
    }
    return SoftwareTokenMFANotFoundException;
}(__BaseException));
export { SoftwareTokenMFANotFoundException };
export var DeviceRememberedStatusType;
(function (DeviceRememberedStatusType) {
    DeviceRememberedStatusType["NOT_REMEMBERED"] = "not_remembered";
    DeviceRememberedStatusType["REMEMBERED"] = "remembered";
})(DeviceRememberedStatusType || (DeviceRememberedStatusType = {}));
export var AdvancedSecurityModeType;
(function (AdvancedSecurityModeType) {
    AdvancedSecurityModeType["AUDIT"] = "AUDIT";
    AdvancedSecurityModeType["ENFORCED"] = "ENFORCED";
    AdvancedSecurityModeType["OFF"] = "OFF";
})(AdvancedSecurityModeType || (AdvancedSecurityModeType = {}));
export var AliasAttributeType;
(function (AliasAttributeType) {
    AliasAttributeType["EMAIL"] = "email";
    AliasAttributeType["PHONE_NUMBER"] = "phone_number";
    AliasAttributeType["PREFERRED_USERNAME"] = "preferred_username";
})(AliasAttributeType || (AliasAttributeType = {}));
var ConcurrentModificationException = (function (_super) {
    __extends(ConcurrentModificationException, _super);
    function ConcurrentModificationException(opts) {
        var _this = _super.call(this, __assign({ name: "ConcurrentModificationException", $fault: "client" }, opts)) || this;
        _this.name = "ConcurrentModificationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ConcurrentModificationException.prototype);
        return _this;
    }
    return ConcurrentModificationException;
}(__BaseException));
export { ConcurrentModificationException };
var ForbiddenException = (function (_super) {
    __extends(ForbiddenException, _super);
    function ForbiddenException(opts) {
        var _this = _super.call(this, __assign({ name: "ForbiddenException", $fault: "client" }, opts)) || this;
        _this.name = "ForbiddenException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ForbiddenException.prototype);
        return _this;
    }
    return ForbiddenException;
}(__BaseException));
export { ForbiddenException };
export var VerifiedAttributeType;
(function (VerifiedAttributeType) {
    VerifiedAttributeType["EMAIL"] = "email";
    VerifiedAttributeType["PHONE_NUMBER"] = "phone_number";
})(VerifiedAttributeType || (VerifiedAttributeType = {}));
var GroupExistsException = (function (_super) {
    __extends(GroupExistsException, _super);
    function GroupExistsException(opts) {
        var _this = _super.call(this, __assign({ name: "GroupExistsException", $fault: "client" }, opts)) || this;
        _this.name = "GroupExistsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, GroupExistsException.prototype);
        return _this;
    }
    return GroupExistsException;
}(__BaseException));
export { GroupExistsException };
export var IdentityProviderTypeType;
(function (IdentityProviderTypeType) {
    IdentityProviderTypeType["Facebook"] = "Facebook";
    IdentityProviderTypeType["Google"] = "Google";
    IdentityProviderTypeType["LoginWithAmazon"] = "LoginWithAmazon";
    IdentityProviderTypeType["OIDC"] = "OIDC";
    IdentityProviderTypeType["SAML"] = "SAML";
    IdentityProviderTypeType["SignInWithApple"] = "SignInWithApple";
})(IdentityProviderTypeType || (IdentityProviderTypeType = {}));
var DuplicateProviderException = (function (_super) {
    __extends(DuplicateProviderException, _super);
    function DuplicateProviderException(opts) {
        var _this = _super.call(this, __assign({ name: "DuplicateProviderException", $fault: "client" }, opts)) || this;
        _this.name = "DuplicateProviderException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, DuplicateProviderException.prototype);
        return _this;
    }
    return DuplicateProviderException;
}(__BaseException));
export { DuplicateProviderException };
export var UserImportJobStatusType;
(function (UserImportJobStatusType) {
    UserImportJobStatusType["Created"] = "Created";
    UserImportJobStatusType["Expired"] = "Expired";
    UserImportJobStatusType["Failed"] = "Failed";
    UserImportJobStatusType["InProgress"] = "InProgress";
    UserImportJobStatusType["Pending"] = "Pending";
    UserImportJobStatusType["Stopped"] = "Stopped";
    UserImportJobStatusType["Stopping"] = "Stopping";
    UserImportJobStatusType["Succeeded"] = "Succeeded";
})(UserImportJobStatusType || (UserImportJobStatusType = {}));
export var EmailSendingAccountType;
(function (EmailSendingAccountType) {
    EmailSendingAccountType["COGNITO_DEFAULT"] = "COGNITO_DEFAULT";
    EmailSendingAccountType["DEVELOPER"] = "DEVELOPER";
})(EmailSendingAccountType || (EmailSendingAccountType = {}));
export var CustomEmailSenderLambdaVersionType;
(function (CustomEmailSenderLambdaVersionType) {
    CustomEmailSenderLambdaVersionType["V1_0"] = "V1_0";
})(CustomEmailSenderLambdaVersionType || (CustomEmailSenderLambdaVersionType = {}));
export var CustomSMSSenderLambdaVersionType;
(function (CustomSMSSenderLambdaVersionType) {
    CustomSMSSenderLambdaVersionType["V1_0"] = "V1_0";
})(CustomSMSSenderLambdaVersionType || (CustomSMSSenderLambdaVersionType = {}));
export var UserPoolMfaType;
(function (UserPoolMfaType) {
    UserPoolMfaType["OFF"] = "OFF";
    UserPoolMfaType["ON"] = "ON";
    UserPoolMfaType["OPTIONAL"] = "OPTIONAL";
})(UserPoolMfaType || (UserPoolMfaType = {}));
export var UsernameAttributeType;
(function (UsernameAttributeType) {
    UsernameAttributeType["EMAIL"] = "email";
    UsernameAttributeType["PHONE_NUMBER"] = "phone_number";
})(UsernameAttributeType || (UsernameAttributeType = {}));
export var DefaultEmailOptionType;
(function (DefaultEmailOptionType) {
    DefaultEmailOptionType["CONFIRM_WITH_CODE"] = "CONFIRM_WITH_CODE";
    DefaultEmailOptionType["CONFIRM_WITH_LINK"] = "CONFIRM_WITH_LINK";
})(DefaultEmailOptionType || (DefaultEmailOptionType = {}));
export var StatusType;
(function (StatusType) {
    StatusType["Disabled"] = "Disabled";
    StatusType["Enabled"] = "Enabled";
})(StatusType || (StatusType = {}));
var UserPoolTaggingException = (function (_super) {
    __extends(UserPoolTaggingException, _super);
    function UserPoolTaggingException(opts) {
        var _this = _super.call(this, __assign({ name: "UserPoolTaggingException", $fault: "client" }, opts)) || this;
        _this.name = "UserPoolTaggingException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UserPoolTaggingException.prototype);
        return _this;
    }
    return UserPoolTaggingException;
}(__BaseException));
export { UserPoolTaggingException };
export var OAuthFlowType;
(function (OAuthFlowType) {
    OAuthFlowType["client_credentials"] = "client_credentials";
    OAuthFlowType["code"] = "code";
    OAuthFlowType["implicit"] = "implicit";
})(OAuthFlowType || (OAuthFlowType = {}));
export var ExplicitAuthFlowsType;
(function (ExplicitAuthFlowsType) {
    ExplicitAuthFlowsType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    ExplicitAuthFlowsType["ALLOW_ADMIN_USER_PASSWORD_AUTH"] = "ALLOW_ADMIN_USER_PASSWORD_AUTH";
    ExplicitAuthFlowsType["ALLOW_CUSTOM_AUTH"] = "ALLOW_CUSTOM_AUTH";
    ExplicitAuthFlowsType["ALLOW_REFRESH_TOKEN_AUTH"] = "ALLOW_REFRESH_TOKEN_AUTH";
    ExplicitAuthFlowsType["ALLOW_USER_PASSWORD_AUTH"] = "ALLOW_USER_PASSWORD_AUTH";
    ExplicitAuthFlowsType["ALLOW_USER_SRP_AUTH"] = "ALLOW_USER_SRP_AUTH";
    ExplicitAuthFlowsType["CUSTOM_AUTH_FLOW_ONLY"] = "CUSTOM_AUTH_FLOW_ONLY";
    ExplicitAuthFlowsType["USER_PASSWORD_AUTH"] = "USER_PASSWORD_AUTH";
})(ExplicitAuthFlowsType || (ExplicitAuthFlowsType = {}));
export var PreventUserExistenceErrorTypes;
(function (PreventUserExistenceErrorTypes) {
    PreventUserExistenceErrorTypes["ENABLED"] = "ENABLED";
    PreventUserExistenceErrorTypes["LEGACY"] = "LEGACY";
})(PreventUserExistenceErrorTypes || (PreventUserExistenceErrorTypes = {}));
export var TimeUnitsType;
(function (TimeUnitsType) {
    TimeUnitsType["DAYS"] = "days";
    TimeUnitsType["HOURS"] = "hours";
    TimeUnitsType["MINUTES"] = "minutes";
    TimeUnitsType["SECONDS"] = "seconds";
})(TimeUnitsType || (TimeUnitsType = {}));
var InvalidOAuthFlowException = (function (_super) {
    __extends(InvalidOAuthFlowException, _super);
    function InvalidOAuthFlowException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidOAuthFlowException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidOAuthFlowException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidOAuthFlowException.prototype);
        return _this;
    }
    return InvalidOAuthFlowException;
}(__BaseException));
export { InvalidOAuthFlowException };
var ScopeDoesNotExistException = (function (_super) {
    __extends(ScopeDoesNotExistException, _super);
    function ScopeDoesNotExistException(opts) {
        var _this = _super.call(this, __assign({ name: "ScopeDoesNotExistException", $fault: "client" }, opts)) || this;
        _this.name = "ScopeDoesNotExistException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ScopeDoesNotExistException.prototype);
        return _this;
    }
    return ScopeDoesNotExistException;
}(__BaseException));
export { ScopeDoesNotExistException };
var UnsupportedIdentityProviderException = (function (_super) {
    __extends(UnsupportedIdentityProviderException, _super);
    function UnsupportedIdentityProviderException(opts) {
        var _this = _super.call(this, __assign({ name: "UnsupportedIdentityProviderException", $fault: "client" }, opts)) || this;
        _this.name = "UnsupportedIdentityProviderException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnsupportedIdentityProviderException.prototype);
        return _this;
    }
    return UnsupportedIdentityProviderException;
}(__BaseException));
export { UnsupportedIdentityProviderException };
export var CompromisedCredentialsEventActionType;
(function (CompromisedCredentialsEventActionType) {
    CompromisedCredentialsEventActionType["BLOCK"] = "BLOCK";
    CompromisedCredentialsEventActionType["NO_ACTION"] = "NO_ACTION";
})(CompromisedCredentialsEventActionType || (CompromisedCredentialsEventActionType = {}));
export var EventFilterType;
(function (EventFilterType) {
    EventFilterType["PASSWORD_CHANGE"] = "PASSWORD_CHANGE";
    EventFilterType["SIGN_IN"] = "SIGN_IN";
    EventFilterType["SIGN_UP"] = "SIGN_UP";
})(EventFilterType || (EventFilterType = {}));
export var DomainStatusType;
(function (DomainStatusType) {
    DomainStatusType["ACTIVE"] = "ACTIVE";
    DomainStatusType["CREATING"] = "CREATING";
    DomainStatusType["DELETING"] = "DELETING";
    DomainStatusType["FAILED"] = "FAILED";
    DomainStatusType["UPDATING"] = "UPDATING";
})(DomainStatusType || (DomainStatusType = {}));
var UnauthorizedException = (function (_super) {
    __extends(UnauthorizedException, _super);
    function UnauthorizedException(opts) {
        var _this = _super.call(this, __assign({ name: "UnauthorizedException", $fault: "client" }, opts)) || this;
        _this.name = "UnauthorizedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnauthorizedException.prototype);
        return _this;
    }
    return UnauthorizedException;
}(__BaseException));
export { UnauthorizedException };
var UnsupportedOperationException = (function (_super) {
    __extends(UnsupportedOperationException, _super);
    function UnsupportedOperationException(opts) {
        var _this = _super.call(this, __assign({ name: "UnsupportedOperationException", $fault: "client" }, opts)) || this;
        _this.name = "UnsupportedOperationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnsupportedOperationException.prototype);
        return _this;
    }
    return UnsupportedOperationException;
}(__BaseException));
export { UnsupportedOperationException };
var UnsupportedTokenTypeException = (function (_super) {
    __extends(UnsupportedTokenTypeException, _super);
    function UnsupportedTokenTypeException(opts) {
        var _this = _super.call(this, __assign({ name: "UnsupportedTokenTypeException", $fault: "client" }, opts)) || this;
        _this.name = "UnsupportedTokenTypeException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, UnsupportedTokenTypeException.prototype);
        return _this;
    }
    return UnsupportedTokenTypeException;
}(__BaseException));
export { UnsupportedTokenTypeException };
export var RecoveryOptionTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccountRecoverySettingTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccountTakeoverActionTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccountTakeoverActionsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NotifyEmailTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NotifyConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AccountTakeoverRiskConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var NumberAttributeConstraintsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var StringAttributeConstraintsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SchemaAttributeTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AddCustomAttributesRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AddCustomAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminAddUserToGroupRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminConfirmSignUpRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminConfirmSignUpResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AttributeTypeFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Value && { Value: SENSITIVE_STRING }))); };
export var AdminCreateUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
})), (obj.ValidationData && {
    ValidationData: obj.ValidationData.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
})), (obj.TemporaryPassword && { TemporaryPassword: SENSITIVE_STRING }))); };
export var MFAOptionTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserTypeFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Attributes && { Attributes: obj.Attributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }) }))); };
export var AdminCreateUserResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.User && { User: UserTypeFilterSensitiveLog(obj.User) }))); };
export var MessageTemplateTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminCreateUserConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminDeleteUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminDeleteUserAttributesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminDeleteUserAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ProviderUserIdentifierTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminDisableProviderForUserRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminDisableProviderForUserResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminDisableUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminDisableUserResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminEnableUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminEnableUserResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminForgetDeviceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminGetDeviceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var DeviceTypeFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.DeviceAttributes && {
    DeviceAttributes: obj.DeviceAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
}))); };
export var AdminGetDeviceResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Device && { Device: DeviceTypeFilterSensitiveLog(obj.Device) }))); };
export var AdminGetUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminGetUserResponseFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
}))); };
export var AnalyticsMetadataTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var HttpHeaderFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ContextDataTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminInitiateAuthRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.AuthParameters && { AuthParameters: SENSITIVE_STRING }))); };
export var NewDeviceMetadataTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AuthenticationResultTypeFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING })), (obj.RefreshToken && { RefreshToken: SENSITIVE_STRING })), (obj.IdToken && { IdToken: SENSITIVE_STRING }))); };
export var AdminInitiateAuthResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
}))); };
export var AdminLinkProviderForUserRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminLinkProviderForUserResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminListDevicesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminListDevicesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminListGroupsForUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var GroupTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminListGroupsForUserResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminListUserAuthEventsRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var ChallengeResponseTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventContextDataTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventFeedbackTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EventRiskTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AuthEventTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminListUserAuthEventsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminRemoveUserFromGroupRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminResetUserPasswordRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminResetUserPasswordResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminRespondToAuthChallengeRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var AdminRespondToAuthChallengeResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
}))); };
export var SMSMfaSettingsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SoftwareTokenMfaSettingsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminSetUserMFAPreferenceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminSetUserMFAPreferenceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminSetUserPasswordRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING }))); };
export var AdminSetUserPasswordResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminSetUserSettingsRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminSetUserSettingsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminUpdateAuthEventFeedbackRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminUpdateAuthEventFeedbackResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminUpdateDeviceStatusRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminUpdateDeviceStatusResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminUpdateUserAttributesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
}))); };
export var AdminUpdateUserAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AdminUserGlobalSignOutRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var AdminUserGlobalSignOutResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AnalyticsConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var AssociateSoftwareTokenRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var AssociateSoftwareTokenResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SecretCode && { SecretCode: SENSITIVE_STRING }))); };
export var ChangePasswordRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.PreviousPassword && { PreviousPassword: SENSITIVE_STRING })), (obj.ProposedPassword && { ProposedPassword: SENSITIVE_STRING })), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var ChangePasswordResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeviceSecretVerifierConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConfirmDeviceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var ConfirmDeviceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserContextDataTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConfirmForgotPasswordRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING }))); };
export var ConfirmForgotPasswordResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ConfirmSignUpRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var ConfirmSignUpResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateGroupRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateGroupResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateIdentityProviderRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var IdentityProviderTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateIdentityProviderResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ResourceServerScopeTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateResourceServerRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ResourceServerTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateResourceServerResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserImportJobTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeviceConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var EmailConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CustomEmailLambdaVersionConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CustomSMSLambdaVersionConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var LambdaConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var PasswordPolicyTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolPolicyTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SmsConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserAttributeUpdateSettingsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UsernameConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolAddOnsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var VerificationMessageTemplateTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserPoolRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserPoolResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var TokenValidityUnitsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserPoolClientRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolClientTypeFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }))); };
export var CreateUserPoolClientResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClient && { UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient) }))); };
export var CustomDomainConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserPoolDomainRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CreateUserPoolDomainResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteGroupRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteIdentityProviderRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteResourceServerRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var DeleteUserAttributesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var DeleteUserAttributesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserPoolRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserPoolClientRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var DeleteUserPoolDomainRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DeleteUserPoolDomainResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeIdentityProviderRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeIdentityProviderResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeResourceServerRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeResourceServerResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeRiskConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var CompromisedCredentialsActionsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var CompromisedCredentialsRiskConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RiskExceptionConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RiskConfigurationTypeFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var DescribeRiskConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RiskConfiguration && { RiskConfiguration: RiskConfigurationTypeFilterSensitiveLog(obj.RiskConfiguration) }))); };
export var DescribeUserImportJobRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeUserImportJobResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeUserPoolRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeUserPoolResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeUserPoolClientRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var DescribeUserPoolClientResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClient && { UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient) }))); };
export var DescribeUserPoolDomainRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DomainDescriptionTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var DescribeUserPoolDomainResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ForgetDeviceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var ForgotPasswordRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var CodeDeliveryDetailsTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ForgotPasswordResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCSVHeaderRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetCSVHeaderResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetDeviceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var GetDeviceResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Device && { Device: DeviceTypeFilterSensitiveLog(obj.Device) }))); };
export var GetGroupRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetGroupResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetIdentityProviderByIdentifierRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetIdentityProviderByIdentifierResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSigningCertificateRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetSigningCertificateResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetUICustomizationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var UICustomizationTypeFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var GetUICustomizationResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UICustomization && { UICustomization: UICustomizationTypeFilterSensitiveLog(obj.UICustomization) }))); };
export var GetUserRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var GetUserResponseFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
}))); };
export var GetUserAttributeVerificationCodeRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var GetUserAttributeVerificationCodeResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetUserPoolMfaConfigRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SmsMfaConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SoftwareTokenMfaConfigTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GetUserPoolMfaConfigResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var GlobalSignOutRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var GlobalSignOutResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var InitiateAuthRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.AuthParameters && { AuthParameters: SENSITIVE_STRING })), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var InitiateAuthResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
}))); };
export var ListDevicesRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var ListDevicesResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListGroupsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListGroupsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListIdentityProvidersRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ProviderDescriptionFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListIdentityProvidersResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListResourceServersRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListResourceServersResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTagsForResourceRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListTagsForResourceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUserImportJobsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUserImportJobsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUserPoolClientsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolClientDescriptionFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var ListUserPoolClientsResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClients && {
    UserPoolClients: obj.UserPoolClients.map(function (item) { return UserPoolClientDescriptionFilterSensitiveLog(item); }),
}))); };
export var ListUserPoolsRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var UserPoolDescriptionTypeFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUserPoolsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUsersRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUsersResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Users && { Users: obj.Users.map(function (item) { return UserTypeFilterSensitiveLog(item); }) }))); };
export var ListUsersInGroupRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var ListUsersInGroupResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Users && { Users: obj.Users.map(function (item) { return UserTypeFilterSensitiveLog(item); }) }))); };
export var ResendConfirmationCodeRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
export var ResendConfirmationCodeResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var RespondToAuthChallengeRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var RespondToAuthChallengeResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
    AuthenticationResult: AuthenticationResultTypeFilterSensitiveLog(obj.AuthenticationResult),
}))); };
export var RevokeTokenRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Token && { Token: SENSITIVE_STRING })), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }))); };
export var RevokeTokenResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SetRiskConfigurationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var SetRiskConfigurationResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RiskConfiguration && { RiskConfiguration: RiskConfigurationTypeFilterSensitiveLog(obj.RiskConfiguration) }))); };
export var SetUICustomizationRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
export var SetUICustomizationResponseFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UICustomization && { UICustomization: UICustomizationTypeFilterSensitiveLog(obj.UICustomization) }))); };
export var SetUserMFAPreferenceRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var SetUserMFAPreferenceResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SetUserPoolMfaConfigRequestFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SetUserPoolMfaConfigResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SetUserSettingsRequestFilterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
export var SetUserSettingsResponseFilterSensitiveLog = function (obj) { return (__assign({}, obj)); };
export var SignUpRequestFilterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING })), (obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
})), (obj.ValidationData && {
    ValidationData: obj.ValidationData.map(function (item) { return AttributeTypeFilterSensitiveLog(item); }),
}))); };
